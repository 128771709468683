import { withDependencies } from '@wix/thunderbolt-ioc'
import { BrowserWindow, BrowserWindowSymbol, CurrentRouteInfoSymbol, PlatformEnvDataProvider } from '@wix/thunderbolt-symbols'
import { ICurrentRouteInfo } from 'feature-router'
import { getBrowserLanguage, isSSR } from '@wix/thunderbolt-commons'

export const locationEnvDataProvider = withDependencies(
	[CurrentRouteInfoSymbol],
	(currentRouteInfo: ICurrentRouteInfo): PlatformEnvDataProvider => {
		return {
			get platformEnvData() {
				const routeInfo = currentRouteInfo.getCurrentRouteInfo()
				const rawUrl = routeInfo!.parsedUrl.href
				return {
					location: {
						rawUrl
					}
				}
			}
		}
	}
)

export const windowEnvDataProvider = withDependencies(
	[BrowserWindowSymbol],
	(window: BrowserWindow): PlatformEnvDataProvider => ({
		platformEnvData: {
			window: {
				isSSR: isSSR(window),
				browserLocale: getBrowserLanguage(window)
			}
		}
	})
)
