import { $W, Experiments, IAppWillLoadPageHandler, PlatformBootstrapData, PlatformEnvData, PlatformLogger, SdkInstance, ViewerModel } from '@wix/thunderbolt-symbols'
import { logSdkError, logSdkWarning } from '@wix/thunderbolt-commons'
import { DynamicRouteData, IRoutingConfig } from 'feature-router'
import { componentSdkFactoryArgs } from '@wix/thunderbolt-platform-types'
import { StorageInitData } from './storage/storage'
import { SiteAssetsClientConfig } from 'thunderbolt-site-assets-client'
import { DynamicSessionModel } from 'feature-session-manager'

export const PlatformInitializerSym = Symbol.for('PlatformInitializer')
export type IPlatform = IAppWillLoadPageHandler & {
	registerWixCodeSdkParams: (params: Record<string, any>) => void
}

export interface PlatformInitializer {
	init(bootstrapData: BootstrapData, viewerAPI: ViewerAPI): Promise<any>
}

export interface ViewerBootstrapData {
	csrfToken: string
	externalBaseUrl: string
	siteRevision: number
	currentPageId: string
	pageJsonFileName: string
	routingInfo: Pick<IRoutingConfig, 'mainPageId' | 'routes'> & { pageId: string; relativeUrl: string }
	wixCodeSdkProviderData: Record<string, Record<string, any>>
	siteAssetsClientInitParams: ViewerModel['siteAssets'] & {
		siteAssetsClientConfig: SiteAssetsClientConfig
		deviceInfo: ViewerModel['deviceInfo']
	}
	experiments: Experiments
	visitorId?: string
	mode: ViewerModel['mode']
	commonConfig: ViewerModel['commonConfig']
	storageInitData: StorageInitData
	routerReturnedData: DynamicRouteData | null
	applicationsInstances: DynamicSessionModel['apps']
	platformEnvData: PlatformEnvData
	sdkFactoriesSiteFeatureConfigs: { [featureName: string]: any }
}

export type BootstrapData = ViewerBootstrapData & PlatformBootstrapData

export type BatchedUpdateFunction = (batchedData: { [compId: string]: any }) => Promise<any> | void

export interface ViewerAPI {
	updateProps: BatchedUpdateFunction
	updateStyles: BatchedUpdateFunction
	invokeSdkHandler: (pageId: string, path: string, ...args: any) => Promise<any> | void
}

type RepeaterItemCallback = (item: DataItem, index: number) => void
export type OnItemRemovedCallback = (item: DataItem) => void
export type RepeaterSdkData = {
	currentItems: Array<DataItem>
	onItemReadyCallbacks: Array<RepeaterItemCallback>
	onItemRemovedCallbacks: Array<OnItemRemovedCallback>
	isItemsSet: boolean
}

export type OnItemCallback = ($item: $W, item: DataItem, index: number) => void

export type DataItem = { _id: string; [key: string]: any }

export interface Reporter {
	logSdkError: typeof logSdkError
	logSdkWarning: typeof logSdkWarning
}

export type ColumnsRepeaterSDK = {
	data: Array<{ _id: string }>
	onItemReady: (cb: OnItemCallback) => void
	onItemRemoved: (cb: OnItemRemovedCallback) => void
	forEachItem: (cb: OnItemCallback) => void
	forItems: (itemIds: Array<string>, cb: OnItemCallback) => void
}

export type AppControllerSDK = {
	type: string | undefined
	on: (event: string, callback: Function, context: any) => void
	off: (event: string, callback: Function) => void
	once: (event: string, callback: Function, context: any) => void
	connectionConfig: any
}

export type componentSdkFactory = (args: componentSdkFactoryArgs) => SdkInstance | Array<SdkInstance> | null
export type ComponentSdks = Record<string, componentSdkFactory>
export type CoreSdkLoaders = Record<string, () => Promise<componentSdkFactory>>
export type ComponentSdksLoader = { loadComponentSdks?: (componentTypes: Array<string>, logger: PlatformLogger) => Promise<ComponentSdks> }
